.home_card_section{
    width: 100%;
    padding: 5rem;
}
.home_card_section h1{
    text-transform: uppercase;
}
.home_card_section_flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.home_card_section_flex > div{
  flex: 1 1 20em;
  margin: 1em;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  font-family: 'Montserrat', sans-serif;
}
.home_card_section_flex > div  p:first-child{
  text-transform: uppercase;
}

 .cardContent::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .cardContent{
    max-height: 200px !important;
    overflow-y: scroll !important;
  }
  .home_card_section_flex div img{
    object-fit: contain;
  }
  @media screen and (min-width: 1000px) and (max-width: 1600px){
    .home_card_section_flex > div{
      flex: 1 1 29% !important;
    }
    .home_card_section{
      padding: 1rem;
    }
    .home_card_section h1{
      padding-top: 4rem ;
      font-size: 2.5rem ;
      margin-top: 2rem ;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1000px){
    .home_card_section_flex > div{
      flex: 1 1 29% !important;
    }
    .home_card_section{
      padding: 1rem;
    }
    .home_card_section h1{
      padding-top: 4rem ;
      font-size: 2rem ;
    }
  }
  @media screen and (max-width: 768px){
    .home_card_section_flex > div{
      flex: 1 1 100% !important;
    }
    .home_card_section{
      padding: 1rem;
    }
    .home_card_section h1{
      padding-top: 4rem;
      font-size: 2rem ;
      margin-top: 2rem ;
    }
  }
  @media screen and (max-width: 640px){
    .home_card_section h1{
      font-size: 1.5rem !important;
    }
  }