@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Source+Sans+Pro:wght@600&display=swap');
/* .css-46bh2p-MuiCardContent-root{
    max-height: 200px !important;
    overflow-y: scroll !important;
}
.css-46bh2p-MuiCardContent-root::-webkit-scrollbar {
    width: 2px;
    height: 5px;
} */
/* .css-46bh2p-MuiCardContent-root::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 5px;
  }
  .css-46bh2p-MuiCardContent-root::-webkit-scrollbar-thumb {
    background: rgb(177, 176, 176);
  } */