body{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.contact{
    background-color: #f5f5f5;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
   
}
.contact__info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
h1{
    font-size: 30px;
    font-weight: bold;
    color: #412626;
    margin: 10px;
}
h5{
    font-size: 20px;
    font-weight: bold;
    color: #412626;
    font-family: 'Montserrat', sans-serif;
   
}
.contact__form{
    display: flex;
    padding-top: 13px;
   
}
.enquiry__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    border: 1px solid #78777a;
    width: 400px;
    height: 700px;
    background-color: #b9b8b8bd;
    border-radius: 5px;
}
.enquiry__form h3{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    margin: 10px;
}
.form__group input{
    width: 300px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #6f6f72;
    background-color: #EEEEEE;
    text-align: center;
    
}
.form__group2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    }

.form__group2 textarea{
    border-radius: 5px;
    border: 1px solid #6f6f72;
    background-color: #EEEEEE;
    text-align: center;
    }
::placeholder{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
   }
.gif{
    width: 60%;
    height: 100%;
    margin-top:50px;
    margin-left:10%;
}
.submit{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #6f6f72;
    background-color: #C40B4D;
    margin-left: 40%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    margin-top: 10px;
}
.submit:hover{
    opacity: .8;
    border: 1px solid #BC631c;
}
@media screen and (max-width :768px){
    .contact__info h5{
        font-size: 15px;

    }
    .gif{
        display: none;
    }
    
}
 
