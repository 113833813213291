.about_main_div{
    display: flex;
    width: 100%;
    height:80vh;
    background: linear-gradient(to right,rgba(0,0,0,1), rgba(0,0,0,0.4)), url(../Assets/diverse-businesspeople-having-meeting.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.aboutHeroText{
    position: absolute;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%,-50%);
    color: #fff;
    text-align: center;
    text-align: left;
    width: 50%;
}
.aboutHeroText h1{
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    text-transform: uppercase;
}
.aboutHeroText p{
    font-family: 'Montserrat', sans-serif; 
    font-size: 1.1rem;
    width: 80%;
    opacity: 0.8;
}
.AboutButton1{
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #C20B4D;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    margin-left: 0 !important;
    margin-right: 20px;
}
.AboutButton1:hover{
    background-color: #910b3c;
}
.aboutOurInspiration{
    width: 100%;
    background-color:#D3D3D3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 5vh;
}
.aboutOurInspiration h1{
    text-transform: uppercase;
}
.aboutOurInspirationHeading{
    width: 100%;
    text-align: center;
}
.aboutOurInspiration_flex{
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-top: 4rem;
    justify-content: space-between;
}
.aboutOurInspiration_flex_left{
    width: 45%;
    position: relative;
}
.aboutOurInspiration_flex_left img{
    bottom: 0;
    position: absolute;
}
.aboutOurInspiration_flex_right{
    width: 50%;
    
}
.aboutOurInspiration_flex_right p{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
    margin: 1.5rem 0;
}
.about_Amrita_VishwaVidyapeetham{
    width: 100%;
    background-color:#fff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 1px;
}
.aboutOurInspiration_flex_left2{
    width: 45%;
}
.aboutOurInspiration_flex_left2 img{
    width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1600px) {
    .aboutHeroText h1{
        font-size: 2.5rem;
    }
}

/* write the media query */
@media (max-width: 768px) {
    .aboutHeroText{
        width: 100%;
        left: 0;
        padding: 0 1rem;
        width: 100%;
    }
    .aboutHeroText h1{
        font-size: 1.5rem;
    }
    .aboutHeroText p{
        font-size: 1rem;
        width: 100%;
    }
    .aboutOurInspiration_flex{
        width: 90%;
        flex-direction: column;
    }
    .aboutOurInspiration_flex_left{
        width: 100%;
        margin-bottom: 2rem;
    }
    .aboutOurInspiration_flex_right{
        width: 100%;
    }
    .aboutOurInspiration_flex_left img{
        position: relative;
    }
    .aboutOurInspiration_flex_right p{
        font-size: 1rem;
    }
    .aboutOurInspiration_flex_left2{
        width: 100%;
    }
    .aboutOurInspiration_flex_left2 img{
        width: 100%;
    }
    .AboutButton1{
        font-size: 1rem;
    }
}