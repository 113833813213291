body{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.career__1{
    background-color: #f5f5f500;
    width: 60%;
}
.career__2{
    background-color: #ffffff00;
    width: 40%;
    
   
   
}
.career__main{
    display: flex;
    height: 100%;
}
h1{
    font-size: 40px;
   font-weight: 900;
    color: #C40B4D;
    margin: 10px;
}
.heading h3{
    color: #C40B4D;
    font-size: 24px;
    font-weight: 600;
}
.heading{
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    height: auto;
    
}
.view_button{
   
    width:100px;
    
   
    margin-bottom: 5%;
    height: auto;
    color:white;
    background-color: #C40B4D;
    border-radius: 5px;
}
.view_button1{
   
    width:100px;
    margin-left: 15%;
    margin-right: 5%;
    margin-bottom: 5%;
    height: auto;
    color:white;
    background-color: #C40B4D;
    border-radius: 5px;
}
.view_button2{
   
    width:100px;
   margin-left: 5%;
   margin-top: 16%;
    margin-bottom: 5%;
    height: auto;
    color:white;
    background-color: #C40B4D;
    border-radius: 5px;
}
.view_apply_btn{
    display: flex;
   
    
}
p{
   text-align: justify;
}
li{
    text-align: left;
    list-style-type: disc;
    
}
.v__ul{
    margin-left: 3%;
}
.v__head{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
    color: #C40B4D;
}
.v__subhead_s{
    font-size: 20px;
    font-weight: 500;
    
    margin-top: 25px;
    

}

.v__subhead{
    font-size: 20px;
    font-weight: 500;
    margin-left: 20%;
    margin-top: 15px;
}
.upload_btn{
    color:rgb(0, 0, 0);
    background-color: #ffffff;
    border-radius: 5px;
    width: 200px;
    border:1px solid black;
}
.apply_{
    display: flex;
    justify-content: space-around;
    
}
.choose label{
    margin-left: 5%;
    margin-top: 4%;
    width: 100%;
}
.choose input{
    margin-left: 5%;
    margin-top: 3%;
    width: 100%;
}
.choose{
    display: flex;
    
}
#tick{
    width: 100%;
    margin-top: 3%;
}

@media only screen and (max-width: 768px) {
   .gif{
         display: none;
   }
   .career__1{
    background-color: #f5f5f500;
    width: 100%;
   }
   .career__heading{
    text-align: center;
   }
   .career__2{
    width:0%;
   }
   .view_button1{
    margin-left: 23%;
   }
   .view_button2{
    margin-left: 23%;
   }
   .heading h3{
    font-size: 20px;

   }
}