.heroSection {
    /* add a  light gradient to the hero section*/
    /* background: linear-gradient(to right,rgba(28,117,188,1), rgba(28,117,188,0.2)), url(../Assets/amria-edge-banner.jpg); */
    background: linear-gradient(to right,rgba(0,0,0,1), rgba(0,0,0,0.2)), url(../Assets/amria-edge-banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    position: relative;
}
.heroSection h1{
    text-transform: uppercase;
}
.heroSectionSvg{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.heroText{
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%,-20%);
    color: #fff;
    text-align: center;
    text-align: left;
    width: 50%;
}
.heroText p{
    font-family: 'Montserrat', sans-serif;
}
.heroText h1{
    font-family: 'Montserrat', sans-serif;
    color: #fff;
}
.heroButton1{
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #C20B4D;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    margin-left: 0 !important;
    margin-right: 20px;
}
.heroButton1:hover{
    background-color: #910b3c;
}
.heroButton2{
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #C20B4D;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.heroButton2:hover{
    background-color: #910b3c;
}
.home_card_section{
    width: 100%;
    padding: 5rem;
}
.home_card_section_flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.home_card_section_flex > div{
    width: 28%;
    margin-bottom: 2rem;
}
.home_testimonials{
    width: 100%;
    padding-bottom: 5vh;
    background-color: #f5f5f5
}
.home_testimonials h1{
    text-transform: uppercase;
}
.home_testimonials_innerDiv{
    width: 80%;
    margin: 0 auto;
    height: 100%;
    background-color: #f5f5f5;
}
.Swiper_Slide{
    width: 100%;
    
    padding: 5rem;
}
.slide_image_div{
    text-align: center;
}
.slide_image_div img{
    display: block;
    margin: 0 auto;
}
.section_testimonials_heading{
    width: 100%;
}
.how_Amrita_edge_helps_you{
    width: 100%;
    padding-top: 2rem;
}
.how_Amrita_edge_helps_you h1{
    text-transform: uppercase;
}
.how_Amrita_edge_helps_you_inner_div{
    display: flex;
    width: 80%;
    margin: 0 auto;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
}
.how_Amrita_edge_helps_you_left_div{
    text-align: left;
    flex: 1 1 50%;
     min-width: 250px;
}
.how_Amrita_edge_helps_you_left_div p{
    text-align: justify;
    font-family: 'Montserrat';
    line-height: 1.8rem;
    opacity: 0.8;
    word-wrap: break-word;
    width: 95%;
}
.how_Amrita_edge_helps_you_right_div{
    flex: 1 1 40%;
}
.how_Amrita_edge_helps_you_right_div img{
    width: 100%;
    border-radius: 10px;
}
.chatbot{
    position: fixed;
    top: 20%;
    right: 10%;
    background-color: white;
    z-index: 100;
    border-radius: 12px;
}
.chatbot_icon{
    position: fixed;
    bottom: 2%;
    right: 8%;
    z-index: 100;
}

.popup{
    width: 300px;
    object-fit: contain;
}
/* write the media query */
/* media query for medium and small laptops */

@media screen and (min-width: 768px) and (max-width: 1600px) {
    .heroText h1{
        font-size: 2.5rem;
    }
    .section_testimonials_heading h1{
        font-size: 2.5rem;
    }
    .how_Amrita_edge_helps_you_left_div{
        text-align: left;
        flex: 1 1 50%;
        min-width: 250px;
    }
    .how_Amrita_edge_helps_you_left_div h1{
        font-size: 2.5rem;
    }
    .how_Amrita_edge_helps_you_left_div p{
        line-height: 1.5rem;
        opacity: 0.8;
    }
    .how_Amrita_edge_helps_you_right_div{
        flex: 1 1 40%;
    }
    .how_Amrita_edge_helps_you_right_div img{
        min-height: 350px;
        object-fit: cover;
        border-radius: 10px;
    }
    .how_Amrita_edge_helps_you_inner_div{
        display: flex;
        width: 90%;
        margin: 0 auto;
        gap: 2rem;
        padding: 1rem;
        align-items: center;
    }
    .how_Amrita_edge_helps_you_right_div img{
        width: 100%;
        border-radius: 10px;
    }
    .chatbot{
        position: fixed;
        top: 12%;
        right: 10%;
        background-color: white;
        z-index: 100;
        border-radius: 12px;
    }
    .popup{
        width: 250px;
        object-fit: contain;
    }
  }

@media screen and (max-width: 768px){
    .heroText h1{
        font-size: 50px !important;
    }
    .home_card_section_flex > div{
        width: 100%;
    }
    .home_testimonials_innerDiv{
        width: 100%;
    }
    .how_Amrita_edge_helps_you_inner_div{
        flex-direction: column;
    }
    .how_Amrita_edge_helps_you_left_div{
        flex: 1 1 100%;
    }
    .how_Amrita_edge_helps_you_left_div h1{
        opacity: 1 !important;
    }
    .how_Amrita_edge_helps_you_right_div{
        flex: 1 1 100%;
    }
    .how_Amrita_edge_helps_you_right_div img{
        height: 100%;
        object-fit: cover;
    }
    .how_Amrita_edge_helps_you_right_div img{
        width: 100%;
    }
    .chatbot{
        position: fixed;
        top: 8%;
        right: 10%;
        background-color: white;
        z-index: 100;
        border-radius: 12px;
    }
    .chatbot_icon{
        position: fixed;
        bottom: 2%;
        right: 8%;
        z-index: 100;
    }
    .popup{
        width: 290px;
        height: auto;
        object-fit: cover;
    }
}
@media screen and (max-width: 571px){
    .heroSectionSvg{
        bottom: -1%;
    }
    .heroText{
        width: 100%;
    }
}
@media screen and (max-width:450px) {
    .how_Amrita_edge_helps_you_left_div h1{
        font-size: 32px;
    }
    .home_testimonials h1{
        font-size: 32px;
    }
}