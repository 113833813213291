.mainFooter_div{
    background-color: #000;
    color: #fff;
    text-align: left;
    padding-top: 5vh;
    padding-bottom: 5vh;
}
.mainFooter_div_Flex{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    height: 100%;
    border-bottom: 1px solid grey;
    gap: 20px;
}
.mainFooter_div p{
    font-size: 1rem;
    font-family: 'montserrat', sans-serif;
    opacity: 0.8;
}
.mainFooter_div h3{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
}
.Footer_logo_div{
   text-align: center;
   width: 100%;
   margin: 1rem auto;
   margin-top: 0;
}
.Footer_logo_div img{
    margin: 0 auto;
}
.socialMedialogs{
display: flex;
width: 80%;
justify-content:space-between;
}

